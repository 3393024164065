import {
  CATEGORIES,
  PROJECTS,
  USERS,
  SECTIONS
} from './mutation-types'
import {
  CategoryProxy
} from "@/proxies/CategoryProxy";
import {
  ProjectProxy
} from "@/proxies/ProjectProxy";
import {
  UsersProxy
} from '@/proxies/UsersProxy'
import {
  SectionsProxy
} from "@/proxies/SectionsProxy";


function sortName(data, field) {
  let sorted = data.sort(function (a, b) {
    if (a[field] < b[field]) { return -1; }
    if (a[field] > b[field]) { return 1; }
    return 0;
  })

  return sorted;

};
export default {
  categories({
    commit
  }) {
    return new Promise((resolve, reject) => {
      new CategoryProxy()
        .all()
        .then(({
          data
        }) => {
          let d = sortName(data, "category_name")
          commit(CATEGORIES, d);
          resolve();
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  projects({
    commit
  }) {
    return new Promise((resolve, reject) => {
      new ProjectProxy()
        .all()
        .then((response) => {
          let d = sortName(response.data, "project_name")
          commit(PROJECTS, d);
          resolve();
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  async main({
    commit, state
  }) {
    var xhr = new XMLHttpRequest();
    let domain = window.location.host == 'localhost:8080' ? 'attornaid.kiva.support' : window.location.host;
    await xhr.open("GET", "https://cloud.kiva.app/support/preferences/portal?p=" + domain, true);
    xhr.send();
    xhr.onreadystatechange = (data) => {
      if (data.srcElement.responseText) {
        let d = JSON.parse(data.srcElement.responseText)
        state.main = d.data
        commit("MAIN", d.data);
      }
    }
  },
  knowledgebasePages({
    commit, state, rootState
  }, data) {
    var xhr = new XMLHttpRequest();
    let uid = state.main.uid;
    let pid = data && data.pid ? '&pid=' + data.pid : '';
    let cid = data && data.cid ? '&cid=' + data.cid : '';
    let lang = state.language == 'tr' ? 'tr' : state.language == 'en' ? 'en' : 'en';
    let kb = state.main['kb_collection_' + lang] || cid;
    console.log({ cid, pid, lang, kb });
    state.pagesLoaded = true
    // console.log("state.language",state.language,"kb",kb)
    xhr.open("GET", "https://cloud.kiva.app/support/pages/list?kb=" + kb + "&uid=" + uid + "" + "" + pid + "", true);
    xhr.send();
    xhr.onreadystatechange = (data) => {
      // console.log("data.srcElement.responseText",data.srcElement.responseText)
      if (data.srcElement.responseText) {
        let d = JSON.parse(data.srcElement.responseText)

        // commit("PAGES", d.data);
        state.pages = d.data
        state.pagesLoaded = false
        // if (this.readyState === 4) {
        //   if ((this.status == 200) && (this.status < 300)) {
        //     commit("PAGES", d.data);
        //   }
        // }
      }
    }
  },
  users({
    commit
  }) {
    return new Promise((resolve, reject) => {
      new UsersProxy()
        .all()
        .then((response) => {
          commit(USERS, response.data);
          resolve();
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  sections({
    commit
  }, params) {

    return new Promise((resolve, reject) => {
      new SectionsProxy()
        .setParameters({
          sort: {
            name: ["sort_order"],
            desc: [false],
          },
        })
        .all()
        .then((response) => {
          let d = sortName(response.data, "section_name")
          commit(SECTIONS, d);
          resolve();
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },

};
